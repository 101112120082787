
import { Vue, Component, Prop } from "vue-property-decorator";

import { User } from "@/interfaces";

export interface Header {
    text: string;
    align: string;
    filterable: boolean;
    sortable: boolean;
    value: string;
}

@Component
export default class FilterableTable extends Vue {
    @Prop({ type: Array, required: true }) readonly headers!: Header[];
    @Prop({ type: Array, required: false }) items!: User[];

    search?: string = "";

    /**
     * Methods
     */
    createItem() {
        this.$emit("user-create");
    }

    updateItem(item) {
        this.$emit("user-update", item);
    }

    deleteItem(item) {
        this.$emit("user-delete", item);
    }
}
